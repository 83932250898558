import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// const protocol = typeof window !== 'undefined' ?  window.location.protocol : '';
// const url = typeof window !== 'undefined' ? window.location.hostname : '';
const Styledfooter = styled.section`
.subsubfooter{
  font-size: .6rem;
    background: #000;
  color: #fff;
  padding: 0.5em;
  font-weight: 300;
  a{
    color: #fff;
  }
}
`;

// function showSomething(istrue){
//  if(istrue){
//    return <div className="someclass"></div>;
//  } 
//}

const Reformfooter = ({ state }) => {
  return (
<Styledfooter>
<div className="footer">
	<div className="container"> 
		<div className="columns is-marginless is-centered">
			<div className="column is-three-quarters has-text-centered"> 
			<p><strong>Reform Pilates Cork</strong> | Unit 1 Celtic Business Park, Monahan Road, Cork</p>
				<p><strong>Tel:</strong> <a href="tel:00353879380484">087 9380484</a> | <strong>Email:</strong> <a href="mailto:reformpilatescork@gmail.com">reformpilatescork@gmail.com</a></p>
				<p><a href="https://www.facebook.com/Reform-Pilates-1771217629691784" target="_blank" rel="noreferrer">Facebook</a> | <a href="https://www.instagram.com/reformpilatescork/" target="_blank" rel="noreferrer">Instagram</a></p>
			</div>
		</div>
	</div>
  </div>
	      <div className="subsubfooter">
      <div className="container has-text-centered">
        © {new Date().getFullYear()},  <Link to="https://limelightmedia.ie">Design</Link> | <Link to="https://doublemarvellous.com">Web</Link>
        </div>
      </div>
</Styledfooter>
  )
}

export default Reformfooter




