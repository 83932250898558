import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Yogo from "../images/logo.png";
import Bsportloginbutton from "../components/bsportloginbutton"
const {useState} = React;

const StyledNavbar = styled.div`

`;

// function showSomething(istrue){
//  if(istrue){
//    return <div className="someclass"></div>;
//  } 
//}




const Navbar = ({ state, location }) => {
  const [open, setOpen] = useState(false);

  const clickHandler = () => {
    setOpen(!open);
  };

  return (
    <StyledNavbar>
    <nav className="navbar" role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
    <Link to="/" className="navbar-item showonmobile" href="">
    <img src={Yogo} alt="Reform Pilates Cork - logo"/>
    </Link>

    <a role="button" className={open ? 'navbar-burger is-active' : 'navbar-burger'} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={clickHandler}>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    </a>
    </div>
    <div id="navbarBasicExample" className={open ? 'navbar-menu is-active' : 'navbar-menu'}>
    <div className="navbar-start">
<div className="navbar-item is-arrowless minwidthlinko">
<Bsportloginbutton/>
</div>
    <div className="navbar-item has-dropdown is-hoverable">
    <Link to="/classes" className="navbar-link minwidthlinko">Classes {console.log(location)}</Link>
    <div className="navbar-dropdown">
    <Link to="https://reformpilatescork.ie/#bsporttimetable" className="navbar-link is-arrowless">
    Find a Class
    </Link>

    <Link to="https://sculpt.reformpilatescork.ie/" className="navbar-link is-arrowless">
September Sculpt
</Link>    


    <Link to="/classes/beginner_reformer" className="navbar-link is-arrowless">Beginner Reformer</Link>

    <Link to="/classes/improvers_reformer" className="navbar-link is-arrowless">Improvers Reformer</Link>

    <Link to="/classes/advanced_reformer" className="navbar-link is-arrowless">Advanced Reformer</Link>

    <Link to="/classes/barre_burn" className="navbar-link is-arrowless">Barre Burn</Link>
{/*    <hr className="navbar-divider" />
    <a href="https://reformpilatescork.ie/#bsporttimetable" className="navbar-link is-arrowless loginlink">All Classes</a>
    <a className="navbar-link is-arrowless loginlink"  target="_blank" rel="noreferrer" href="https://reformpilatescork.typeform.com/to/E4Gxdx" data-type="CLASSIC" data-link-id="28069151" data-link-title="New Member Registration" data-link-url="https://reformpilatescork.typeform.com/to/E4Gxdx" data-account-id="5855219" data-account-username="reformpilatescork" data-thumbnail="">New Member Registration</a>
    <a className="navbar-link is-arrowless loginlink"  target="_blank" rel="noreferrer" href="https://reformpilatescork.ie/#bsporttimetable" data-type="CLASSIC" data-link-id="28069190" data-link-title="Current Member Sign In" data-link-url="https://reformpilatescork.ie/#bsporttimetable" data-account-id="5855219" data-account-username="reformpilatescork" data-thumbnail="">Current Member Sign In</a>
*/}
    </div>
    </div>
    <div className="navbar-item has-dropdown is-hoverable">
    <Link to="/aboutus" className="navbar-link is-arrowless minwidthlinko">About</Link>

    <div className="navbar-dropdown">
    <Link to="/blog" className="navbar-link is-arrowless">Blog</Link>
    </div>

    </div>

    </div>
    <div className="navbar-center hideonmobile">
    <Link to="/" className="centerlogo">
    <img src={Yogo} alt="Reform Pilates Cork"/>
    </Link>
    </div>
    <div className="navbar-end">
    <div className="navbar-item has-dropdown is-hoverable">
    <Link to="/booknow" className="navbar-link minwidthlinko">Book Now</Link>
    <div className="navbar-dropdown">
    <Link to="https://reformpilatescork.ie/#bsportpasses" className="navbar-link is-arrowless">
    Get a Pass
    </Link>
    <hr className="navbar-divider" />
    <a className="navbar-link is-arrowless" href="https://reformpilatescork.voucherconnect.com/">
    Gift Cards
    </a>
    </div>
    </div>

    <Link to="/contact" className="navbar-link is-arrowless minwidthlinko">
    Contact Us
    </Link>


    </div>
    </div>
    </nav>
    </StyledNavbar>
    )
}

export default Navbar




