import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Navbar from "../components/navbar"
import Reformfooter from "../components/footer"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>

      <Seo title="404: Not Found" />
           <Navbar />
<section className="internal-page-main gencont">
  <div className="container">
    <div className="columns is-marginless is-centered">
    <div className="column is-9">
<h3>Error 404</h3><hr/>
<p>Sorry, that doesn't exist!</p>
      </div>
  </div>
  </div>
</section>
<Reformfooter/>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
