import * as React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"
const Styledsection = styled.section`

`;

// function showSomething(istrue){
//  if(istrue){
//    return <div className="someclass"></div>;
//  } 
//}

const Bsportloginbutton = () => {
  
  //const showpopper = state.vars.showpopper;
  return (
<Styledsection>
<Helmet>    
<script type='text/javascript' id="insert-bsport-widget-cdn">{`!function (b, s, p, o, r, t) { !typeof window.BsportWidget !== "undefined" && !document.getElementById("bsport-widget-cdn") && !function () { m = b.createElement(s), m.id = "bsport-widget-cdn", m.src = p, b.getElementsByTagName("head")[0].appendChild(m) }() }(document, "script", "https://cdn.bsport.io/scripts/widget.js")`}</script>
    <script type='text/javascript'id="bsport-widget-mount">
        {`function MountBsportWidget(config, repeat=1) {
            if (repeat > 50) { return }
            if (!window.BsportWidget) {
                return setTimeout(() => {
                    MountBsportWidget(config,repeat+1)
                }, 100 * repeat || 1)
            }
            BsportWidget.mount(config)
        }`}
    </script>
    <script type='text/javascript'>
        {`MountBsportWidget({
                "parentElement": "bsport-widget-741277",
                "companyId": 1189,
                "franchiseId": null,
                "dialogMode": 2,
                "widgetType": "loginButton", 
                "showFab": false,
                "fullScreenPopup": false,
                "styles":undefined,
                "config": {
                    "loginButton": {}
                }  
            })`}
    </script>
</Helmet>

<div><div id="bsport-widget-741277"/></div>
</Styledsection>
  )
}

export default Bsportloginbutton
